<div class="container">
  <div class="row align-items-center">
    <input type="file" class="file-input btn btn-primary d-none" (change)="onChange($event)" accept=".pdf,.doc,.docx"
      #fileUpload multiple />

    <div class="btn btn-primary text-white w-170">
      <span class="me-5" (click)="fileUpload.click()">{{ "Choose a file" }}
      </span>
      <i class="fa-solid fa-file fa-2xl" style="color:white"></i>
    </div>
  </div>

  <!-- <input type="file" class="file-input" multiple (change)="onChange($event)" /> -->

  <div *ngIf="files.length" class="row align-items-center">
    <div class="col-12">
      <section *ngFor="let file of files">
        File details:
        <ul class=>
          <li>Name: {{file.name}}</li>
          <li>Type: {{file.type}}</li>
          <li>Size: {{file.size}} bytes</li>
        </ul>
      </section>
    </div>
    <div class="col-6 d-flex ">
      <button (click)="onUpload()" class="btn btn-primary btn-sm text-white">Upload</button>
      <button (click)="reset()" class="btn btn-primary btn-sm ms-3 text-white">Clear</button>
    </div>
    <!-- <section [ngSwitch]="status">
    <p *ngSwitchCase="'uploading'">⏳ Uploading...</p>
    <p *ngSwitchCase="'success'">✅ Done!</p>
    <p *ngSwitchCase="'fail'">❌ Error!</p>
    <p *ngSwitchDefault>😶 Waiting to upload...</p>
  </section> -->
  </div>
</div>