//import { Injectable } from '@angular/core';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable, of } from 'rxjs';
//import { environment } from '../../environments/environment';
//import { User } from '../dtos/user/User';
//import { UserDto } from '../dtos/user/UserDto';
//import { Case } from '../dtos/case/Case';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Case } from "../dtos/case/Case";
import { User } from "../dtos/user/User";
import { UpdateUserDto, UserDto } from "../dtos/user/UserDto";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    })
  };

  private apiUrl = environment.api+"users";
  

  constructor(private http: HttpClient) { }

  getUsersV1(): Observable<UserDto[]> {
    return this.http.get<UserDto[]>(`${this.apiUrl}`, this.httpOptions); 
  }

  getUserById(userId: number): Observable<UserDto> {
    return this.http.get<UserDto>(`${this.apiUrl}/${userId}`, this.httpOptions);
  }

  getUserByEmail(email: string): Observable<UserDto> {
    const payload = { email: email };
    return this.http.post<UserDto>(`${this.apiUrl}/email`, payload, this.httpOptions);
  }
  
  getUserAllRoles(): Observable<UserDto> {
    // const payload = { role: role };
    return this.http.get<UserDto>(`${this.apiUrl}/roles`, this.httpOptions);
  }

  createUser(newUser: UserDto): Observable<UserDto> {
    return this.http.post<UserDto>(this.apiUrl, newUser, this.httpOptions);
  }

  updateUser(userId: number, userToUpdate: UpdateUserDto): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${userId}`, userToUpdate, this.httpOptions);
  }
  // updateUser(userId: number, userToUpdate: UserDto): Observable<void> {
  //   return this.http.put<void>(`${this.apiUrl}/${userId}`, userToUpdate, this.httpOptions);
  // }

 
  // updatePassword(userId: number, Password: string, newPassword: string): Observable<void> {
  //   //const payload = { Password, newPassword };
  //   const payload = {  "password": Password,  "newPassword": newPassword };
  //   return this.http.post<void>(`${this.apiUrl}/${userId}/password`, payload);
  // }

  // updatePassword(userId: number, oldPassword: string, newPassword: string): Observable<void> {
  //   const payload = { password: oldPassword, newPassword };
  //   return this.http.post<void>(`${this.apiUrl}/${userId}/password`, payload, this.httpOptions);
  // }
  // comparePasswords(userId: number, password: string): Observable<boolean> {
  //   const payload = { password };
  //   return this.http.post<boolean>(`${this.apiUrl}/${userId}/compare-passwords`, payload, this.httpOptions);
  // }

  updatePassword(userId: number, oldPassword: string, newPassword: string): Observable<void> {
    const payload = { password: oldPassword, newPassword };
    return this.http.put<void>(`${this.apiUrl}/${userId}/password`, payload);
  }
  comparePasswords(userId: number, password: string): Observable<boolean> {
    const payload = { password };
    return this.http.post<boolean>(`${this.apiUrl}/${userId}/compare-passwords`, payload);
  }

  deleteUser(userId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${userId}`);
  }


  // Coreact Methods
  getCorrectPassword(userId: number): Observable<{ password: string }> {
    return this.http.get<{ password: string }>(`${this.apiUrl}/${userId}`, this.httpOptions);
  }



  getUsers():User[]{
    return <User[]>[
      {
        "userId": 0,
        "username": "Nikos",
        "passwordHash": null,
        "email": "test@test.com",
        "firstName": "Nikos",
        "lastName": "Karagiannis",
        "accessLevel": "test0",
        "isActive": false,
        "createdDate": "2024-01-01T00:00:00",
        "updatedDate": "2024-01-01T00:00:00",
      },
      {
        "userId": 1,
        "username": "Giorgos",
        "passwordHash": null,
        "email": "test@test.com",
        "firstName": "Giorgos",
        "lastName": "Polyxronopoulos",
        "accessLevel": "test1",
        "isActive": false,
        "createdDate": "0001-01-01T00:00:00",
        "updatedDate": "0001-01-01T00:00:00",
      },
      {
        "userId": 2,
        "username": "Alex",
        "passwordHash": null,
        "email": "test@test.com",
        "firstName": "Alex",
        "lastName": "Alexiou",
        "accessLevel": "test2",
        "isActive": false,
        "createdDate": "0001-01-01T00:00:00",
        "updatedDate": "0001-01-01T00:00:00",
      },
      {
        "userId": 3,
        "username": "Giannis",
        "passwordHash": null,
        "email": "test@test.com",
        "firstName": "Giannis",
        "lastName": "Giannakopoulos",
        "accessLevel": null,
        "isActive": false,
        "createdDate": "0001-01-01T00:00:00",
        "updatedDate": null,
      },
      {
        "userId": 4,
        "username": "Sakis",
        "passwordHash": null,
        "email": "test@test.com",
        "firstName": "Sakis",
        "lastName": "Stasinopoulos",
        "accessLevel": null,
        "isActive": false,
        "createdDate": "0001-01-01T00:00:00",
        "updatedDate": null,
      }
    ];
  }
}
