import { HttpClient } from '@angular/common/http';
import { Component, NgModule } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CommonModule } from '@angular/common';
import { throwError } from 'rxjs/internal/observable/throwError';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports:[CommonModule],
})
export class FileUploadComponent {
  fileName = '';
  fileToUpload: File | null = null;
  status: 'initial' | 'uploading' | 'success' | 'fail' = 'initial'; // Variable to store file status

  files: File[] = [];

  constructor(private http: HttpClient) {}
  onChange(event: any) {
    const files = event.target.files;

    if (files.length) {
      this.status = "initial";
      this.files = files;
    }
  }

  onUpload() {
    if (this.files.length) {
      const formData = new FormData();

      [...this.files].forEach((file) => {
        formData.append("file", file, file.name);
      });

      const upload$ = this.http.post("https://httpbin.com/post", formData);

      this.status = "uploading";

      upload$.subscribe({
        next: () => {
          this.status = "success";
        },
        error: (error: any) => {
          this.status = "fail";
          return throwError(() => error);
        },
      });
    }
  }
  reset(){
    this.files = [] ;
  }
}
