import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseDto } from '../../dtos/case/CaseDto';
import { FraudData4 } from '../../dtos/case/FraudData4';
import { CaseService } from '../../services/case.service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Case } from '../../dtos/case/Case';
import { User } from '../../dtos/user/User';
//delete
import { ManageUsersComponent } from '../manage-users/manage-users.component';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/users.service';
import { AuthService } from '../../modules/auth';
import { UserDto } from '../../dtos/user/UserDto';

@Component({
  selector: 'app-investigation-table',
  templateUrl: './investigation-table.component.html',
  styleUrls: ['./investigation-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  
})
export class InvestigationTableComponent implements OnInit {
  loadingData: boolean = true;
  report: boolean = false;
  currentItem: number = 1;
  stopEmpiricalRulesPolling: boolean = true;

  processData: boolean;
  casesStatus: number = 0;
  stopAccidentProfileUnsupervisedPolling: boolean;
  rows: Case[];

  caseTemp: Case;

  //signed user vars
  signedUsr: any;

  reorderable: boolean;
  expandedRows: Set<any> = new Set();

  managerUser: ManageUsersComponent[];
  env: {
    brand: { code: number; name: string; logo: string };
    production: boolean;
    appVersion: string;
    USERDATA_KEY: string;
    isMockEnabled: boolean;
    apiUrl: string;
    api: string;
    reportUrl: string;
    appThemeName: string;
    appPurchaseUrl: string;
    appHTMLIntegration: string;
    appPreviewUrl: string;
    appPreviewAngularUrl: string;
    appPreviewDocsUrl: string;
    appPreviewChangelogUrl: string;
    imagesServerUrl: string;
    appDemos: {
      demo1: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo2: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo3: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo4: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo5: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo6: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo7: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo8: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo9: {
        title: string;
        description: string;
        //this.startPollingForConsumedMessages();
        published: boolean;
        thumbnail: string;
      };
      demo10: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo11: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo12: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo13: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo14: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo15: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo16: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo17: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo18: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo19: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo20: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
    };
  };

  constructor(
    // private cdRef: ChangeDetectorRef,
    private router: Router,
    private caseService: CaseService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private userService: UserService,
    private authService : AuthService
  ) {
    //this.startPollingForConsumedMessages();

    route.params.subscribe((p) => {
      this.casesStatus = +p['id'];
    });
  }

  users: UserDto[];

  cases: Case[] = [];
  stopPolling: boolean = true;
  stopUnsupervisedPolling: boolean = true;
  @ViewChild('myTable') table: any;
  expanded: any = {};
  timeout: any;
  ColumnMode = ColumnMode;
  temp: any = [];
  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //console.log('paged!', event);
    }, 100);
  }


  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);

    // Instead of manually setting the style attribute, let Angular handle the animation
    // Ensure that each row has an 'expanded' property for tracking its state
    row.expanded = !row.expanded;
    this.rows = [...this.rows]; // Trigger change detection by creating a new array reference
  }
  onDetailToggle(event: { value: any }) {
    const row = event.value;
    // console.log('onDetailToggle', row);
    // Toggle the 'expanded' property for the row
    if (this.expandedRows.has(row)) {
      this.expandedRows.delete(row);
      row.expanded = false;
    } else {
      this.expandedRows.add(row);
      row.expanded = true;
    }

    // Update the rows to reflect the changes
    this.rows = [...this.rows]; // Trigger change detection by creating a new array reference
  }
  ngOnInit(): void {
    this.userService.getUsersV1().subscribe((result:any) => {
      if (result) {
        this.users = result;
        this.cd.detectChanges();
      }
    });

    this.getCases()
    
  }

  public getCases():any{
    this.authService.currentUser$.subscribe((admin:any)=>{
      this.caseService.getCasesV2().subscribe(
        (result) => {
          if (result) {
            this.loadingData = false;
            this.env = environment;
            this.cases = this.processCases(result);
            this.temp = [...this.cases];
            this.cases = this.temp.filter(
              (d: { caseManager: any }) =>
               d.caseManager.toLowerCase()==admin?.fullname.toLowerCase()
            );
            this.rows = this.cases;

            this.calculateCounts()
            this.setAdditionalStatistics()

            this.cd.detectChanges();
          }
        },
        (error) => {
          console.error('Error fetching cases:', error);
        }
      );
    });
  }

  processCases(cases: any[]): any[] {
    return cases.map((caseItem) => {
      return {
        caseCode: caseItem.fraudDataId + 1000,
        // removed the .toLocaleDateString('en-GB')
        caseDate: new Date(caseItem.started),
        caseManager: caseItem.caseManager,
        modelResult: caseItem.anomalyDetection || '-',
        incidentProfile:
          caseItem.accidentProfile == null
            ? '-'
            : caseItem.accidentProfile == 1
            ? 'Outlier'
            : 'Regular',
        unsupervisedScore: caseItem.empiricalRulesScore
          ? caseItem.empiricalRulesScore.toFixed(2) + '%'
          : '-',
        modelResultCharacerization: new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(caseItem.costEstimator),
        claimAmount: caseItem.totalClaimAmount
          ? new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'EUR',
            }).format(caseItem.totalClaimAmount)
          : '-',
        claimSeverity: caseItem.caseSeverity,
        priorityIndex: '1',
        systemResult: caseItem.systemResult,
        investigationResults:
          caseItem.investigationResults == '-'
            ? 'unreviewed'
            : caseItem.investigationResults,

        // caseCode: caseItem.fraudDataId + 1000,
        // caseDate: new Date(caseItem.started).toLocaleDateString('en-GB'),
        // caseManager: caseItem.caseManager,
        // anomalyDetection: caseItem.anomalyDetection || '-',
        // anomalyDetectionClass: this.getUnsupervisedTextColor(caseItem.anomalyDetection),
        // accidentProfile: caseItem.accidentProfile == null ? '-' : (caseItem.accidentProfile == 1 ? 'Outlier' : 'Regular'),
        // accidentProfileClass: this.getAccidentProfileTextColor(caseItem.accidentProfile),
        // empiricalRulesScore: caseItem.empiricalRulesScore ? caseItem.empiricalRulesScore.toFixed(2) + '%' : '-',
        // empiricalRulesScoreClass: this.getEmpiricalRulesTextColor(caseItem.empiricalRulesScore),
        // costEstimator: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(caseItem.costEstimator),
        // totalClaimAmount: caseItem.totalClaimAmount ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(caseItem.totalClaimAmount) : '-',
        // caseSeverity: caseItem.caseSeverity,
        // priorityIndex: (caseItem.priorityIndex * 100).toFixed(2) + '%',
        // systemResult: caseItem.systemResult,
        // investigationResult: caseItem.investigationResult,
      };
    });
  }
  columns = [
    { prop: 'caseCode', name: 'Claim ID' },
    { prop: 'caseDate', name: 'Claim Date' },
    { prop: 'systemResult', name: 'System Result' },
    { prop: 'claimAmount', name: 'Actual Claim Amount' },
    { prop: 'claimSeverity', name: 'Claim Severity' },
    { prop: 'investigationResult', name: 'Status' },
    { prop: 'modelResult', name: 'Price Anomaly Detection' },
    { prop: 'incidentProfile', name: 'Expenses Profile' },
    { prop: 'unsupervisedScore', name: 'Ruled Based System' },
    { prop: 'modelResultCharacerization', name: 'Estimated Claim Amount' },
    { prop: 'priorityIndex', name: 'Priority Index' },
    { prop: 'caseManager', name: 'Case Manager' },
  ];


  // GET THE FILTERING
  getFilter(event: any){
    // Show the filtered result
    this.rows = event;
  }

 

  getBackgroundColor(dataScore: string) {
    let color = './assets/media/media/red.png';
    if (Number(dataScore) <= 0.7999) {
      color = './assets/media/media/orange.png';
      if (Number(dataScore) <= 0.3999) {
        color = './assets/media/media/green.png';
      }
    } else if (!dataScore) {
      color = './assets/media/media/green.png';
    }
    return color;
  }
  getSeverityBadge(severity: string) {
    let badgeClass: string;

    switch (severity.toLowerCase()) {
      case 'low':
        badgeClass = ' badge-success';
        break;
      case 'medium':
        badgeClass = 'badge-warning';
        break;
      case 'high':
        badgeClass = 'badge-danger';
        break;
      default:
        badgeClass = 'badge-secondary'; // default case if severity doesn't match
    }

    return (
      'badge w-50 p-3 align-self-center badge-square badge-lg ' + badgeClass
    );
  }

  getRowClass = () => {
    return 'card m-1 p-3';
  };

  getTextColor(dataScore: string) {
    let color = 'text-danger fw-bolder text-hover-primary d-block mb-1 fs-6';
    if (Number(dataScore) <= 0.7999) {
      color = 'text-warning fw-bolder text-hover-primary d-block mb-1 fs-6';
      if (Number(dataScore) <= 0.3999) {
        color = 'text-success fw-bolder text-hover-primary d-block mb-1 fs-6';
      }
    } else if (!dataScore) {
      color = 'text-success fw-bolder text-hover-primary d-block mb-1 fs-6';
    }
    return color;
  }

  getUnsupervisedTextColor(dataScore: string) {
    let color = 'text-danger fw-bolder text-hover-primary  mb-1 fs-6';
    if (dataScore === 'Outlier') {
      color = 'text-warning fw-bolder text-hover-primary  mb-1 fs-6';
    } else if (dataScore === 'Regular') {
      color = 'text-success fw-bolder text-hover-primary  mb-1 fs-6';
    } else if (!dataScore) {
      color = 'text-success fw-bolder text-hover-primary  mb-1 fs-6';
    }
    return color;
  }

  getAccidentProfileTextColor(dataScore: string) {
    if (!dataScore) {
      return 'visibility:hidden';
    }

    let color = 'text-danger fw-bolder text-hover-primary  mb-1 fs-6';
    if (dataScore === '1') {
      color = 'text-warning fw-bolder text-hover-primary  mb-1 fs-6';
    } else if (dataScore === '0') {
      color = 'text-success fw-bolder text-hover-primary  mb-1 fs-6';
    } else if (!dataScore) {
      color = 'text-success fw-bolder text-hover-primary  mb-1 fs-6';
    }
    return color;
  }

  getEmpiricalRulesTextColor(dataScore: number) {
    let color = 'text-danger fw-bolder text-hover-primary  mb-1 fs-6';
    if (Number(dataScore) <= 80) {
      color = 'text-warning fw-bolder text-hover-primary  mb-1 fs-6';
      if (Number(dataScore) <= 40) {
        color = 'text-success fw-bolder text-hover-primary  mb-1 fs-6';
      }
    } else if (!dataScore) {
      color = 'text-success fw-bolder text-hover-primary  mb-1 fs-6';
    }
    return color;
  }

  getSystemResultsTextColor(systemResult: string) {
    if (systemResult && systemResult.toLowerCase() === 'suspicious') {
      return 'bg-danger ';
    } else {
      return 'bg-success';
    }
  }



  totalCasesCount:number=0;
  suspiciousCasesCount: number = 0;
  notSuspiciousCasesCount: number = 0;
  closedCasesCount: number = 0;
  casesThisMonthCount: number = 0;
  averageCasesPerDay: number = 0;
  
  calculateCounts() {
    // This function is called once when the component is initialized
    this.rows = this.cases;
    console.log('this.cases',this.cases);
    this.totalCasesCount = this.rows.length;
    // this.totalCases.emit(this.totalCasesCount);
    // console.log('length', this.cases);
    this.suspiciousCasesCount = this.rows.filter(
      (caseItem : Case) => caseItem.systemResult.toLowerCase() === 'suspicious'
    ).length;
    this.notSuspiciousCasesCount = this.rows.filter(
      (caseItem : Case) => caseItem.systemResult.toLowerCase() === 'notsuspicious'
    ).length;
  }
  setAdditionalStatistics(): void {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Assuming 'caseStatus' is a property of the case item that indicates if a case is closed
    this.closedCasesCount = this.rows.filter(
      (caseItem) => caseItem.investigationResults.toLowerCase() === 'reviewed'
    ).length;

    // Assuming 'reportDate' is a Date object or a string that can be converted to a Date object
    this.casesThisMonthCount = this.rows.filter((caseItem) => {
      const reportDate = new Date(caseItem.created);
      return (
        reportDate.getMonth() === currentMonth &&
        reportDate.getFullYear() === currentYear
      );
    }).length;

    // Calculate the average cases per day (assuming 'reportDate' is within the current year for simplicity)
    const startOfYear = new Date(currentYear, 0, 1);
    const daysElapsed = Math.ceil(
      (currentDate.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24)
    );
    this.averageCasesPerDay = this.rows.length / daysElapsed;
  }

}