import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Case } from 'src/app/dtos/case/Case';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { InvestigationTableComponent } from 'src/app/pages/investigation-table/investigation-table.component';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    trigger('datatableAnimation', [
      state(
        'expanded',
        style({
          height: '280', // Use the actual height of the element
          opacity: 1,
          overflow: 'hidden',
        })
      ),
      state(
        'collapsed',
        style({
          height: '0', // Collapse the element to zero height
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out', style({ height: 'auto !important' })), // Duration and easing of the animation
      ]),
    ]),

    trigger('slideUpDown', [
      state(
        'expanded',
        style({
          height: '*', // Use the actual height of the element
          opacity: 1,
          overflow: 'hidden',
        })
      ),
      state(
        'collapsed',
        style({
          height: '0', // Collapse the element to zero height
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out'), // Duration and easing of the animation
      ]),
    ]),
  ],
})
export class FilterComponent {
  // cases:Case[]=[];
  @Input() rows: any[];
  @Input() cases: Case[];
  @Input() totalCases: number;
  @Input() susCases: number;
  @Input() notSusCases: number;
  @Input() closedCases: number;
  @Input() averageCasePerDay: number;
  @Output() appliedRows = new EventEmitter<any>();
  // @Output() rows: EventEmitter<Case> = new EventEmitter<Case>();

  // cases: Case[] = [];

  expanded: any = {};
  timeout: any;
  temp: any = [];

  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  constructor() {}

  resetRows() {
    this.resetFields();
    //Reset the rows of the table to original data
    this.temp = this.cases;
    //Reset the filtered rows to original data
    this.rows = this.cases;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
    this.appliedRows.emit(this.rows);
  }

  resetFields() {
    //reset the value to empty string
    let selectTag = document.querySelectorAll('select');
    selectTag.forEach((element) => {
      element.value = '';
    });

    //Clears ID field
    const id = document.querySelector('input[name="id"]') as HTMLInputElement;
    id.value = 'Filter by ID...';
    //Clears Date Field
    const fromDateInput = document.querySelector(
      'input[name="fromDate"]'
    ) as HTMLInputElement;
    const toDateInput = document.querySelector(
      'input[name="toDate"]'
    ) as HTMLInputElement;

    fromDateInput.value = '';
    toDateInput.value = '';
  }

  apply() {
    this.temp = this.rows;
    // update each filter function based on the selected options/values
    this.updateFilterByClaimSeverity();
    this.updateFilterByInvestigationResult();
    this.updateFilterByDateRange();
    this.updateFilterById();
    this.updateFilterBySystemResult();
    this.updateFilterByPriorityIndex();


    this.rows = this.temp;
    this.appliedRows.emit(this.rows);
  }

  /*Listens for key press  and updates the result*/
  @HostListener('document:keyup', ['$event'])
  handleEnterKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.apply();
    }
  }

  updateFilterByClaimSeverity() {
    const claimSeveritySelect = document.querySelector(
      'select[name="claimSeverity"]'
    ) as HTMLSelectElement;
    if (claimSeveritySelect) {
      const selectedSeverity = claimSeveritySelect.value.toLowerCase();
      if (selectedSeverity !== '') {
        this.temp = this.temp.filter((d: { caseSeverity: any }) => {
          if (d.caseSeverity !== null) {
            return d.caseSeverity.toLowerCase() === selectedSeverity;
          }
        });
      }
    }
  }

  updateFilterByInvestigationResult() {
    const investigationResultSelect = document.querySelector(
      'select[name="investigationResult"]'
    ) as HTMLSelectElement;
    if (investigationResultSelect) {
      const selectedResult = investigationResultSelect.value.toLowerCase();
      if (selectedResult !== '') {
        this.temp = this.temp.filter((d:any) => {
          return d.investigationResults.toLowerCase() === selectedResult;
        });
      }
    }
  }

  updateFilterByDateRange() {
    const fromDateInput = document.querySelector(
      'input[name="fromDate"]'
    ) as HTMLInputElement;
    const toDateInput = document.querySelector(
      'input[name="toDate"]'
    ) as HTMLInputElement;

    const fromDateVal = new Date(fromDateInput.value);
    const toDateVal = new Date(toDateInput.value);

    if (fromDateInput.value && toDateInput.value) {
      this.temp = this.temp.filter((d: { caseDate: Date }) => {
        const caseDate = new Date(d.caseDate);
        return caseDate >= fromDateVal && caseDate <= toDateVal;
      });
    }
  }

  updateFilterById() {
    const idInput = document.querySelector(
      'input[name="id"]'
    ) as HTMLInputElement;
    const idValue = Number(idInput.value);

    if (!isNaN(idValue) && idInput.value.length === 4) {
      this.temp = this.temp.filter(
        (d: { caseCode: any }) => Number(d.caseCode) === idValue
      );
    }
  }
  updateFilterBySystemResult() {
    const sysResSelect = document.querySelector(
      'select[name="systemResult"]'
    ) as HTMLSelectElement;
    if (sysResSelect) {
      const selectedResult = sysResSelect.value.toLowerCase();
      if (selectedResult !== '') {
        this.temp = this.temp.filter((d: { systemResult: any }) => {
          d.systemResult = d.systemResult.split(' ').join('');
          return d.systemResult.toLowerCase() === selectedResult;
        });
      }
    }
  }

  updateFilterByPriorityIndex() {
    const minPriorityElement = document.querySelector(
      'input[name="priorityIndexMin"]'
    ) as HTMLInputElement | null;

    const maxPriorityElement = document.querySelector(
      'input[name="priorityIndexMax"]'
    ) as HTMLInputElement | null;

    if (!minPriorityElement || !maxPriorityElement) {
      console.error("Priority index input elements not found");
      return;
    }

    const minPriority = parseInt(minPriorityElement.value, 10);
    const maxPriority = parseInt(maxPriorityElement.value, 10);

    if (isNaN(minPriority) || isNaN(maxPriority)) {
      return;
    }

    if (minPriority < maxPriority)
    {
      this.temp = this.temp.filter(
        (d: { priorityIndex: any }) => {
          const priorityIndex = Number(d.priorityIndex);
          const result = priorityIndex * 100 >= minPriority && priorityIndex * 100<= maxPriority;
          // Log each comparison result
          return result;
        }
      );

    }
  }





  getTotalCasesCount(): number {
    return this.cases.length;
  }

  getSuspiciousCasesCount(): number {
    return this.cases.filter(
      (caseItem) => caseItem.systemResult.toLowerCase() === 'suspicious'
    ).length;
  }

  getNotSuspiciousCasesCount(): number {
    return this.cases.filter(
      (caseItem) => caseItem.systemResult.toLowerCase() === 'notsuspicious'
    ).length;
  }

  showFilters: boolean = false;
  showStatistics: boolean = false;

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  toggleStatistics() {
    this.showStatistics = !this.showStatistics;
  }
}
