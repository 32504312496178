import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CaseDataResult } from '../data-results/case/caseDataResult';
//import { CaseDataListResult } from '../data-results/case/caseDataListResult';
//import { CaseDataResult } from '../data-results/case/caseDataResult';
//import { MemberDataResult } from '../data-results/member/memberDataResult';
import { Assignment, BulkAssignment, Case } from '../dtos/case/Case';
import { CaseDto } from '../dtos/case/CaseDto';
import { EmpiricalRules } from '../dtos/case/EmpiricalRules';
import { EmpiricalRulesDataResult } from '../dtos/case/EmpiricalRulesDataResult';
import { FeatureImportance } from '../dtos/case/FeautureImportance';
import { FraudData4 } from '../dtos/case/FraudData4';
import { SimilarImageDto } from '../dtos/case/SimilarImageDto';
import { Image } from '../dtos/case/Image';
import { InvestigationResult } from '../dtos/case/investigation-result';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = environment.api;
  //private memberUrl = 'http://185.138.41.53:4203/api/';
  //private memberUrl = 'http://api.insurancephi.eu/api/';

  constructor(private http: HttpClient) {}

  getCases(): Observable<FraudData4[]> {
    return this.http.get<FraudData4[]>(
      `${this.apiUrl}frauddata`,
      this.httpOptions
    );
  }

  getCasesV2(): Observable<Case[]> {
    return this.http.get<Case[]>(`${this.apiUrl}Cases`, this.httpOptions);
  }

  runCostEstimatorModel(): Observable<FraudData4[]> {
    return this.http.get<FraudData4[]>(
      `${this.apiUrl}CostEstimator`,
      this.httpOptions
    );
  }

  runDataModelUnsupervised(): Observable<FraudData4[]> {
    return this.http.get<FraudData4[]>(
      `${this.apiUrl}Unsupervised`,
      this.httpOptions
    );
  }

  runFeatureImportanceModel(): Observable<FraudData4[]> {
    return this.http.get<FraudData4[]>(
      `${this.apiUrl}FeatureImportanceModel`,
      this.httpOptions
    );
  }

  runAccidentProfileModel(): Observable<FraudData4[]> {
    return this.http.get<FraudData4[]>(
      `${this.apiUrl}OutlierDetection`,
      this.httpOptions
    );
  }

  runEmpiricalRulesModel(): Observable<FraudData4[]> {
    return this.http.get<FraudData4[]>(
      `${this.apiUrl}EmpiricalRules`,
      this.httpOptions
    );
  }

  getCostEstimatorModelCasesNotProcessed(): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}CostEstimatorCasesNotProcessed`,
      this.httpOptions
    );
  }

  getAccidentProfileUnsupervisedCasesNotProcessed(): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}AccidentProfileCasesNotProcessed`,
      this.httpOptions
    );
  }

  getOutlierDetectionCasesNotProcessed(): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}OutlierDetectionCasesNotProcessed`,
      this.httpOptions
    );
  }

  getFeatureImportanceCasesNotProcessed(): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}FeatureImportanceCasesNotProcessed`,
      this.httpOptions
    );
  }

  getEmpiricalRulesCasesNotProcessed(): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}EmpiricalRulesCasesNotProcessed`,
      this.httpOptions
    );
  }

  getCaseById(caseId: number): Observable<CaseDataResult> {
    return this.http.get<CaseDataResult>(
      `${this.apiUrl}cases/` + caseId,
      this.httpOptions
    );
  }

  getFeatureImportanceById(fraudDataId: number): Observable<FeatureImportance> {
    return this.http.get<FeatureImportance>(
      `${this.apiUrl}FeatureImportance/` + fraudDataId,
      this.httpOptions
    );
  }

  getEmpiricalRulesById(
    fraudDataId: number
  ): Observable<EmpiricalRulesDataResult> {
    return this.http.get<EmpiricalRulesDataResult>(
      `${this.apiUrl}EmpiricalRulesV2/` + fraudDataId,
      this.httpOptions
    );
  }

  getHcaReportById(fraudDataId: number): Observable<string> {
    return this.http.get<string>(
      `${this.apiUrl}hcaReport/` + fraudDataId,
      this.httpOptions
    );
  }

  getCaseByIdV2(caseId: number): Observable<FraudData4> {
    return this.http.get<FraudData4>(
      `${this.apiUrl}casesV2/` + caseId,
      this.httpOptions
    );
  }

  updateCase(caseId: number, caseData: Case): Observable<FraudData4> {
    return this.http.put<FraudData4>(
      `${this.apiUrl}case/` + caseId,
      caseData,
      this.httpOptions
    );
  }

  createCase(caseData: CaseDto): Observable<CaseDto> {
    return this.http.post<CaseDto>(`${this.apiUrl}case`, caseData);
  }

  processPriorityIndex(): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}PriorityIndex`,
      this.httpOptions
    );
  }

  updateCaseByAssignment(
    caseId: number,
    caseData: Assignment
  ): Observable<Assignment> {
    return this.http.put<Assignment>(
      `${this.apiUrl}case/` + caseId,
      caseData,
      this.httpOptions
    );
  }

  bulkAssingCases(request: BulkAssignment): Observable<BulkAssignment>{
    return this.http.put<BulkAssignment>(
      `${this.apiUrl}cases/`,
      request,
      this.httpOptions
    )
  }

  getInvestigationResults(): Observable<InvestigationResult> {
    return this.http.get<InvestigationResult>(
      `${this.apiUrl}investigation-results/`,
      this.httpOptions
    );
  }
  updateInvestigationResultByID(caseId: number, invStatusID: number []) {
    return this.http.put(
      `${this.apiUrl}case/${caseId}/investigation-result/`,
      invStatusID,
      this.httpOptions
    );
  }

  updateApprovalStatus(caseId: number, caseStatus: boolean) {
    /**
     *
     * caseStatus == 0 for reject
     * caseStatus == 1 for approve
     */
    return this.http.put(
      `${this.apiUrl}case/${caseId}/approval-status`,
      caseStatus,
      this.httpOptions
    );
  }

  //CoReact service methods

  getImagesById(fraudDataId: number): Observable<Image[]> {
    return this.http.get<Image[]>(
      `${this.apiUrl}Images/` + fraudDataId,
      this.httpOptions
    );
  }

  getSimilarImagesById(fraudDataId: number): Observable<SimilarImageDto[]> {
    return this.http.get<SimilarImageDto[]>(
      `${this.apiUrl}CaseSimilarImages/` + fraudDataId,
      this.httpOptions
    );
  }

  getCoReactCaseById(caseId: number): Observable<CaseDataResult> {
    return this.http.get<CaseDataResult>(
      `${this.apiUrl}cases/` + caseId,
      this.httpOptions
    );
  }

  updateCaseV2(caseId: number, caseData: Case): Observable<Case> {
    return this.http.put<Case>(
      `${this.apiUrl}case/` + caseId,
      caseData,
      this.httpOptions
    );
  }

  // updateCaseV2(caseId: number, caseData: Assign): Observable<Assign> {
  //   return this.http.put<Assign>(`${this.apiUrl}case/` + caseId, caseData, this.httpOptions);
  // }
}
