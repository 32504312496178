<div>
  <h3>Reviewed Cases</h3>

  <!-- Switches Container -->
  <div class="container shadow card p-5">
    <app-filter
      [cases]="cases"
      [rows]="rows"
      [totalCases]="totalCasesCount"
      [susCases]="suspiciousCasesCount"
      [notSusCases]="notSuspiciousCasesCount"
      [closedCases]="closedCasesCount"
      [averageCasePerDay]="averageCasesPerDay"
      (appliedRows)="getFilter($event)"
    ></app-filter>
    
    <ngx-datatable
      #myTable
      class="bootstrap"
      [rows]="rows"
      [columns]="columns"
      [columnMode]="ColumnMode.force"
      [headerHeight]="60"
      [footerHeight]="80"
      [summaryRow]="false"
      [summaryPosition]="'top'"
      [limit]="40"
      [rowHeight]="60"
      [reorderable]="false"
      (page)="onPage($event)"
    >
      <ngx-datatable-row-detail
        [rowHeight]="280"
        #myDetailRow
        [@datatableAnimation]="expanded ? 'expanded' : 'collapsed'"
        (toggle)="onDetailToggle($event)"
      >
        <ng-template let-row="row" ngx-datatable-row-detail-template>
          <div class="detail-row-container">
            <!-- Detail Row Columns -->
            <div class="detail-row-grid">
              <!-- Left Column -->
              <div class="detail-row-column">
                <ng-container *ngFor="let col of columns">
                  <div
                    *ngIf="
                      [
                        'modelResult',
                        'incidentProfile',
                        'unsupervisedScore'
                      ].includes(col.prop)
                    "
                    class="detail-row-item"
                  >
                    <strong class="detail-row-label">{{ col.name }}:</strong>
                    <span class="detail-row-value">{{ row[col.prop] }}</span>
                  </div>
                </ng-container>
              </div>

              <!-- Right Column -->
              <div class="detail-row-column">
                <ng-container *ngFor="let col of columns">
                  <div
                    *ngIf="
                      ['modelResultCharacerization', 'caseManager'].includes(
                        col.prop
                      )
                    "
                    class="detail-row-item"
                  >
                    <strong class="detail-row-label">{{ col.name }}:</strong>
                    <span class="detail-row-value">{{ row[col.prop] }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- Detail Row Footer with Buttons -->
            <div class="row mt-5">
              <div class="col d-flex align-items-center">
                <a
                  *ngIf="env.brand.code == 1"
                  [routerLink]="['/case-detail/', row.caseCode - 1000]"
                  class="btn btn-warning"
                >
                  CASE DETAILS
                </a>

                <a
                  *ngIf="env.brand.code == 2"
                  [routerLink]="['/case-detail/', row.caseCode - 1000]"
                  class="text-dark fw-bolder text-hover-primary fs-6"
                >
                  CASE DETAILS
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column
        [width]="40"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template
          let-row="row"
          let-expanded="expanded"
          ngx-datatable-cell-template
        >
          <a
            href="javascript:void(0)"
            class="expand-collapse-icon ps-3"
            title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)"
          >
            <i
              [class]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
            ></i>
          </a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Claim ID" prop="caseCode">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="case-code-class">{{ row.caseCode }}</div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Received Date" prop="caseDate">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="case-date-class">
            {{ row.caseDate | date : "dd-MM-yyyy " }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="System Status" prop="systemResult">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div>
            <span
              class="system-result-indicator"
              [class]="getSystemResultsTextColor(row.systemResult)"
            ></span
            >{{ row.systemResult }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Claim Amount" prop="claimAmount">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="claim-amount-container text-end">
            <span
              class="claim-amount-value fw-bold text-gray-700 me-2 lh-1 ls-n2"
              >{{ row.claimAmount.replace("€", "") }}</span
            >
            <span class="claim-amount-currency">&euro;</span>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Claim Severity"
        prop="claimSeverity"
        [cellClass]="'text-center'"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div [class]="getSeverityBadge(row.claimSeverity || '')">
            {{ row.claimSeverity }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Investigation Status"
        prop="investigationResult"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="investigation-result-class">
            {{ row.investigationResult }}
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
