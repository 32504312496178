import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FaviconService } from './services/favicon.service';


@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  env: {
    brand: { code: number; name: string; logo: string };
    production: boolean;
    appVersion: string;
    USERDATA_KEY: string;
    isMockEnabled: boolean;
    apiUrl: string;
    api: string;
    reportUrl: string;
    appThemeName: string;
    appPurchaseUrl: string;
    appHTMLIntegration: string;
    appPreviewUrl: string;
    appPreviewAngularUrl: string;
    appPreviewDocsUrl: string;
    appPreviewChangelogUrl: string;
    imagesServerUrl: string;
    appDemos: {
      demo1: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo2: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo3: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo4: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo5: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo6: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo7: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo8: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo9: {
        title: string;
        description: string;
        //this.startPollingForConsumedMessages();
        published: boolean;
        thumbnail: string;
      };
      demo10: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo11: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo12: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo13: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo14: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo15: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo16: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo17: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo18: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo19: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
      demo20: {
        title: string;
        description: string;
        published: boolean;
        thumbnail: string;
      };
    };
  };

  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private titleService: Title,private faviconService: FaviconService
  ) {

    this.env = environment;
    if(this.env.brand.code==1){
      this.titleService.setTitle('Coreact');
      this.faviconService.setFavicon('assets/media/logos/coreact.png');
    }else{
      this.titleService.setTitle('Coassess');
      this.faviconService.setFavicon('assets/media/logos/coassess-small.png');
    }
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.modeService.init();
  }
}
