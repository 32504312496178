export class AuthModel {
  authToken: string;
  token: string;
  refreshToken: string;
  expiresIn: Date;
  email:string;
  username:string;
  firstName:string;
  lastName:string;
  accessLevel:number;
  userId: number;


  setAuth(auth: AuthModel) {
    this.token = auth.token;
    this.authToken = auth.authToken;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
    this.email = auth.email;
    this.username = auth.username;
    this.firstName = auth.firstName;
    this.lastName = auth.lastName;
    this.accessLevel = auth.accessLevel;
    this.userId = auth.userId;
  }
}
