<div class="row justify-content-end">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <button class="btn btn-link" type="button" (click)="toggleStatistics()">
          Statistics
        </button>
        <!-- Toggle Button for Statistics Section -->
        <button
          class="btn btn-link toggle-btn"
          type="button"
          (click)="toggleStatistics()"
        >
          <!-- Close Icon (visible only when Statistics are expanded) -->
          <i *ngIf="showStatistics" class="fas fa-times fa-2x"></i>
          <!-- Open Icon (visible only when Statistics are collapsed) -->
          <i *ngIf="!showStatistics" class="fas fa-chevron-down fa-2x"></i>
        </button>
      </div>
      <div [@slideUpDown]="showStatistics ? 'expanded' : 'collapsed'">
        <div class="card-body pt-0">
          <div class="row">
            <!-- First Column -->
            <div class="col-md-4">
              <!-- Total Claims Count -->
              <div
                class="d-flex align-items-center bg-light-primary rounded p-5 mb-7"
              >
                <!-- begin::Icon -->
                <span class="me-5">
                  <i class="fas fa-briefcase text-primary fa-lg"></i>
                </span>
                <!-- end::Icon -->
                <!-- begin::Title -->
                <div class="flex-grow-1 me-2">
                  <a class="fw-bolder text-gray-800 text-hover-primary fs-6">
                    Total Claims
                  </a>
                  <span class="text-muted fw-bold d-block">{{
                    5148 | number
                  }}</span>
                  <!-- <span class="text-muted fw-bold d-block">{{
                    totalCases
                  }}</span> -->
                </div>
                <!-- end::Title -->
              </div>
              <!-- Closed Claims Count -->
              <div
                class="d-flex align-items-center bg-light-danger rounded p-5 mb-7"
              >
                <!-- begin::Icon -->
                <span class="me-5">
                  <i class="fas fa-archive text-danger fa-lg"></i>
                </span>
                <!-- end::Icon -->
                <!-- begin::Title -->
                <div class="flex-grow-1 me-2">
                  <a class="fw-bolder text-gray-800 text-hover-primary fs-6">
                    Closed Claims
                  </a>
                  <!-- <span class="text-muted fw-bold d-block">{{
                    closedCases
                  }}</span> -->
                  <span class="text-muted fw-bold d-block">{{
                    5148 | number
                  }}</span>
                </div>
                <!-- end::Title -->
              </div>
            </div>
            <!-- Second Column -->
            <div class="col-md-4">
              <!-- Suspicious Claims Count -->
              <div
                class="d-flex align-items-center bg-light-warning rounded p-5 mb-7"
              >
                <!-- begin::Icon -->
                <span class="me-5">
                  <i class="fas fa-exclamation-triangle text-warning fa-lg"></i>
                </span>
                <!-- end::Icon -->
                <!-- begin::Title -->
                <div class="flex-grow-1 me-2">
                  <a class="fw-bolder text-gray-800 text-hover-primary fs-6">
                    Suspicious Claims
                  </a>
                  <span class="text-muted fw-bold d-block">{{ susCases }}</span>
                </div>
                <!-- end::Title -->
              </div>
              <!-- Claims This Month Count -->
              <div
                class="d-flex align-items-center bg-light-info rounded p-5 mb-7"
              >
                <!-- begin::Icon -->
                <span class="me-5">
                  <i class="fas fa-calendar-alt text-info fa-lg"></i>
                </span>
                <!-- end::Icon -->
                <!-- begin::Title -->
                <div class="flex-grow-1 me-2">
                  <a class="fw-bolder text-gray-800 text-hover-primary fs-6">
                    Claims This Month
                  </a>
                  <span class="text-muted fw-bold d-block">{{
                    435 | number
                  }}</span>
                  <!-- <span class="text-muted fw-bold d-block">{{
                    notSusCases
                  }}</span> -->
                </div>
                <!-- end::Title -->
              </div>
            </div>
            <!-- Third Column -->
            <div class="col-md-4">
              <!-- Not Suspicious Claims Count -->
              <div
                class="d-flex align-items-center bg-light-success rounded p-5 mb-7"
              >
                <!-- begin::Icon -->
                <span class="me-5">
                  <i class="fas fa-check-circle text-success fa-lg"></i>
                </span>
                <!-- end::Icon -->
                <!-- begin::Title -->
                <div class="flex-grow-1 me-2">
                  <a class="fw-bolder text-gray-800 text-hover-primary fs-6">
                    Not Suspicious Claims
                  </a>
                  <span class="text-muted fw-bold d-block">{{
                    notSusCases
                  }}</span>
                </div>
                <!-- end::Title -->
              </div>
              <!-- Average Claims Per Day -->
              <div class="d-flex align-items-center bg-light-muted rounded p-5">
                <!-- begin::Icon -->
                <span class="me-5">
                  <i class="fas fa-chart-line text-muted fa-lg"></i>
                </span>
                <!-- end::Icon -->
                <!-- begin::Title -->
                <div class="flex-grow-1 me-2">
                  <a class="fw-bolder text-gray-800 text-hover-primary fs-6">
                    Average Claims Per Day
                  </a>
                  <span class="text-muted fw-bold d-block">{{
                    25 | number : "1.0-2"
                  }}</span>
                  <!-- <span class="text-muted fw-bold d-block">{{
                    averageCasePerDay | number : "1.0-2"
                  }}</span> -->
                </div>
                <!-- end::Title -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters Section: Expandable -->
    <div class="card">
      <div class="card-header">
        <button class="btn btn-link" type="button" (click)="toggleFilters()">
          Filters
        </button>
        <!-- Close Button -->
        <!-- Close Button (visible only when expanded) -->
        <button
          class="btn btn-link toggle-btn"
          type="button"
          (click)="toggleFilters()"
        >
          <!-- Close Icon (visible only when expanded) -->
          <i *ngIf="showFilters" class="fas fa-times fa-2x"></i>
          <!-- Open Icon (visible only when collapsed) -->
          <i *ngIf="!showFilters" class="fas fa-chevron-down fa-2x"></i>
        </button>
      </div>
      <div [@slideUpDown]="showFilters ? 'expanded' : 'collapsed'">
        <div class="card-body">
          <!-- Filter content here -->
          <div class="row g-3">
            <!-- First Column: Switches and Case ID Filter -->
            <div class="col-md-4">
              <!-- Suspicious and Not Suspicious Dropbox -->
              <div class="mb-3">
                <select class="form-select mb-7" name="systemResult">
                  <option value="" selected>Filter by System Result:</option>
                  <option value="suspicious">Suspicious</option>
                  <option value="notSuspicious">Not Suspicious</option>
                </select>
              </div>
              <!-- Filter by Case ID -->
              <input
                type="number"
                name="id"
                class="form-control mb-2"
                placeholder="Filter by ID..."
              />
            </div>

            <!-- Second Column: Select Filters for Claim Severity and Investigation Result -->
            <div class="col-md-4">
              <!-- Filter by Claim Severity -->
              <select class="form-select mb-7 claimSev" name="claimSeverity">
                <option value="" selected>Filter by Claim Severity:</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
              <!-- Filter by Investigation Result -->
              <select class="form-select" name="investigationResult">
                <option value="" selected>
                  Filter By Investigation Result
                </option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="under investigation">Under Investigation</option>
                <option value="Pending">Pending</option>
              </select>
            </div>
            <div class="col-md-4 d-flex flex-column justify-content-between">
              <input
                type="number"
                name="priorityIndexMin"
                class="form-control mb-2"
                placeholder="Minimum priority index value..."
                min="0"
                max="100"
              />
              <input
                type="number"
                name="priorityIndexMax"
                class="form-control mb-2"
                placeholder="Maximum priority index value..."
                min="0"
                max="100"
              />
            </div>

            <!-- Third Column: Date Range Filter -->
            <div class="col-md-4">
              <!-- Filter by Date Range -->
              <div class="input-group">
                <span class="input-group-text">Date Range</span>
                <input type="date" class="form-control" name="fromDate" />
                <input type="date" class="form-control" name="toDate" />
              </div>
            </div>
          </div>

          <!-- New Row for Action Buttons -->
          <div class="row mt-3">
            <div class="col-md-12 d-flex justify-content-end">
              <!-- Action Buttons: Reset and Apply -->
              <button class="btn btn-secondary me-2" (click)="resetRows()">
                Reset
              </button>
              <button class="btn btn-success" (click)="apply()">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
